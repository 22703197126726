import { degodsAttributeTraitMap } from "./degodsAttributeTraitMap";
import { checkIfDegods } from "./getProjectID";

export const y00tsAttributeTraitMap = new Map([
  // Attributes
  ["background", "Background"],
  ["clothes", "Clothes"],
  ["eyewear", "Eyewear"],
  ["face", "Face"],
  ["fur", "Fur"],
  ["head", "Head"],
  ["1/1", "1/1"],
  ["all", "All"],

  // Traits
  ["marshmallow", "Marshmallow"],
  ["vanilla_ice", "Vanilla Ice"],
  ["solitary_star", "Solitary Star"],
  ["snow_white", "Snow White"],
  ["powder_puff", "Powder Puff"],
  ["phantom_green", "Phantom Green"],
  ["cannoli_cream", "Cannoli Cream"],
  ["buttercream", "Buttercream"],
  ["bit_of_blue", "Bit of blue"],
  ["antique_white", "Antique White"],

  ["ace_cardigan", "Ace Cardigan"],
  ["army_t-shirt", "Army T-shirt"],
  ["banana_hazmat", "Banana Hazmat"],
  ["banana_puffer", "Banana Puffer"],
  ["baseball_hoodie", "Baseball Hoodie"],
  ["basketball_jersey", "Basketball Jersey"],
  ["windbreaker_(blackout)", "Windbreaker (blackout)"],
  ["black_puff_jacket", "Black Puff Jacket"],
  ["classic_suit", "Classic Suit"],
  ["black_tracksuit", "Black Tracksuit"],
  ["blue_sky_hoodie", "Blue Sky Hoodie"],
  ["rugby_shirt", "Rugby Shirt"],
  ["blue_headphones", "Blue Headphones"],
  ["sunday_shirt", "Sunday Shirt"],
  ["cashmere_turtleneck", "Cashmere Turtleneck"],
  ["cherry_puffer", "Cherry Puffer"],
  ["summer_shirt", "Summer Shirt"],
  ["cream_flannel", "Cream Flannel"],
  ["marshmallow_puffer", "Marshmallow Puffer"],
  ["thrifted_denim_jacket", "Thrifted Denim Jacket"],
  ["c.r.e.a.m._hoodie", "C.R.E.A.M. Hoodie"],
  ["nice_overalls", "Nice Overalls"],
  ["fast_jacket", "Fast Jacket"],
  ["angler_vest", "Angler Vest"],
  ["football_jersey", "Football Jersey"],
  ["bear_coat", "Bear Coat"],
  ["happy_hoodie", "Happy Hoodie"],
  ["miracle_jersey", "Miracle Jersey"],
  ["stolen_hotel_robe", "Stolen Hotel Robe"],
  ["jacksonhole_jacket", "Jacksonhole Jacket"],
  ["mcy00ts_polo", "McY00ts Polo"],
  ["mechanic", "Mechanic"],
  ["blob_suit", "Blob Suit"],
  ["varsity_jacket", "Varsity Jacket"],
  ["orange_hoodie", "Orange Hoodie"],
  ["rare_t-shirt", "Rare T-shirt"],
  ["the_hoodie", "The Hoodie"],
  ["professor", "Professor"],
  ["none", "None"],
  ["billionaire_zip-up", "Billionaire Zip-up"],

  ["radiohead_squares", "Radiohead Squares"],
  ["pollocks", "Pollocks"],
  ["banksy_goggles", "Banksy Goggles"],
  ["grand_budapest_shades", "Grand Budapest Shades"],
  ["last_supper_squares", "Last Supper Squares"],
  ["hockney_squares", "Hockney Squares"],
  ["6d_glasses", "6d Glasses"],
  ["abbey_roads", "Abbey Roads"],
  ["rembrandt_goggles", "Rembrandt Goggles"],
  ["lensless_glasses", "Lensless Glasses"],
  ["rounded_squares_(blackout)", "Rounded Squares (blackout)"],
  ["blue_light_glasses", "Blue Light Glasses"],
  ["trapezoids", "Trapezoids"],
  ["cobain_goggles", "Cobain Goggles"],
  ["mona_lisas", "Mona Lisas"],
  ["dieter_rams", "Dieter Rams"],
  ["24k_gold_blocks", "24k Gold Blocks"],
  ["golden_hour_frames", "Golden Hour Frames"],
  ["green_bricks", "Green Bricks"],
  ["heatwaves", "Heatwaves"],
  ["ive_unibody", "Ive Unibody"],
  ["matisse_frames", "Matisse Frames"],
  ["mondrian_squares", "Mondrian Squares"],
  ["monet_squares", "Monet Squares"],
  ["melrose_bricks", "Melrose Bricks"],
  ["nouns", "Nouns"],
  ["orange_soda_shades", "Orange Soda Shades"],
  ["none", "None"],
  ["retro_apple", "Retro Apple"],
  ["windsors_(blackout)", "Windsors (blackout)"],
  ["tints_(blue)", "Tints (blue)"],
  ["tints_(red)", "Tints (red)"],
  ["tints_(yellow)", "Tints (yellow)"],
  ["sunflowers", "Sunflowers"],
  ["cali_sunset", "Cali Sunset"],
  ["aviators_(blackout)", "Aviators (blackout)"],
  ["van_goghs", "Van Goghs"],
  ["warhols", "Warhols"],
  ["wayfarers_(blackout)", "Wayfarers (blackout)"],
  ["win-win_shades", "Win-Win Shades"],

  ["chestnut", "Chestnut"],
  ["hazel", "Hazel"],
  ["tumbleweed", "Tumbleweed"],
  ["eggnog", "Eggnog"],
  ["wheat", "Wheat"],
  ["sand_dollar", "Sand Dollar"],
  ["bone", "Bone"],
  ["stone", "Stone"],
  ["pewter", "Pewter"],
  ["pink_marshmallow", "Pink Marshmallow"],
  ["paradise_green", "Paradise Green"],

  ["24k_gold_shoey_guzzler", "24k Gold Shoey Guzzler"],
  ["backwards_snapback", "Backwards Snapback"],
  ["undercut", "Undercut"],
  ["headphones", "Headphones"],
  ["big_cowboy_hat", "Big Cowboy Hat"],
  ["beanie_(blackout)", "Beanie (blackout)"],
  ["minimalist_camo_cap", "Minimalist Camo Cap"],
  ["champ_hat", "Champ Hat"],
  ["chill_hat", "Chill Hat"],
  ["color_block_halo", "Color Block Halo"],
  ["cool_beanie", "Cool Beanie"],
  ["bucket_hat", "Bucket Hat"],
  ["down_bad_snapback", "Down Bad Snapback"],
  ["frank_headband", "Frank Headband"],
  ["free_snapback", "Free Snapback"],
  ["fuzzy_banana_bucket_hat", "Fuzzy Banana Bucket Hat"],
  ["happy_hat", "Happy Hat"],
  ["skinny_headband", "Skinny Headband"],
  ["mcy00ts_visor", "McY00ts Visor"],
  ["money_bands", "Money Bands"],
  ["mullet", "Mullet"],
  ["banana_beanie", "Banana Beanie"],
  ["orange_beanie", "Orange Beanie"],
  ["pyrite_crown", "Pyrite Crown"],
  ["flame_hat", "Flame Hat"],
  ["helmet", "Helmet"],
  ["red_horns", "Red Horns"],
  ["rockstar_hair", "Rockstar Hair"],
  ["skull_trucker_hat", "Skull Trucker Hat"],
  ["spiky_hair", "Spiky Hair"],
  ["truck_trucker", "Truck Trucker"],
  ["w_visor", "W Visor"],
  ["windswept_hair", "Windswept Hair"],
  ["none", "None"],

  ["chill", "Chill"],
  ["smile", "Smile"],
  ["smirk", "Smirk"],
  ["wholesome", "Wholesome"],
  ["blasé", "Blasé"],

  ["abstract_y00t", "Abstract y00t"],
  ["aqua_y00t", "Aqua y00t"],
  ["astroy00t", "Astroy00t"],
  ["balloon_y00t", "Balloon y00t"],
  ["cloud_y00t", "Cloud y00t"],
  ["coded_y00t", "Coded y00t"],
  ["colored_pencil_y00t", "Colored Pencil y00t"],
  ["deconstructed_y00t", "Deconstructed y00t"],
  ["doodle_y00t", "Doodle y00t"],
  ["elder_y00t", "Elder y00t"],
  ["FACIAL_EXPRESSIONS_STUDY", "FACIAL EXPRESSIONS STUDY"],
  ["FRANK'S_SCRIBBLE", "FRANK'S SCRIBBLE"],
  ["ghosted_y00t", "Ghosted y00t"],
  ["giga_y00t", "Giga y00t"],
  ["golden_y00t", "Golden y00t"],
  ["ice_cream_king_y00t", "Ice Cream King y00t"],
  ["mermaid_y00t", "Mermaid y00t"],
  ["mini(dino)", "Mini(dino)"],
  ["mini(whale)", "Mini(whale)"],
  ["MODELING_CLAY_SCULPTURE", "MODELING CLAY SCULPTURE"],
  ["monochromatic_zombie_y00t", "Monochromatic Zombie y00t"],
  ["mt.y00topia", "Mt.y00topia"],
  ["phantom_y00t", "Phantom y00t"],
  ["pixel_y00t", "Pixel y00t"],
  ["pixie_y00t", "Pixie y00t"],
  ["plant-based_y00t", "Plant-based y00t"],
  ["queen_y00t", "Queen y00t"],
  ["renaissance_y00t", "Renaissance y00t"],
  ["sk8r_y00t", "Sk8r y00t"],
  ["skeleton_y00t", "Skeleton y00t"],
  ["slime_y00t", "Slime y00t"],
  ["sludge_y00t", "Sludge y00t"],
  ["underworld_y00t", "Underworld y00t"],
  ["vintage_y00t", "Vintage y00t"],
  ["weird_y00t", "Weird y00t"],
  ["wolf_of_y00tstreet", "Wolf Of y00tstreet"],
  ["x-ray_y00t", "X-ray y00t"],
  ["y00t_on_train", "y00t On Train"],
  ["y00t_on_wall", "y00t On Wall"],
  ["y00ts_&_crafts", "y00ts & Crafts"],
]);

export const getAttributeTraitValue = (key: string) => {
  const isDegods = checkIfDegods()
  if (isDegods) {
    return degodsAttributeTraitMap.get(key) || ""
  }
  return y00tsAttributeTraitMap.get(key) || "";
}

export const getAttributeTraitKey = (mapValue: string) => {
  const isDegods = checkIfDegods()
  var result = "";
  Object.entries(Object.fromEntries(isDegods ? degodsAttributeTraitMap : y00tsAttributeTraitMap)).forEach(
    ([key, value]: [string, string]) => {
      if (value.toLowerCase() === mapValue.toLowerCase()) result = key;
    }
  );
  return result;
};
